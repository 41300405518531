import React from 'react';

import { graphql } from 'gatsby';
import Divider from '../../components/divider/divider';
import Intro from '../../components/intro/Intro';
import PortfolioLink from '../../components/portfolio-links/portfolioLink';
import IndexLayout from '../../layouts';

const Realizacje = ({ data }) => {
  return (
    <IndexLayout>
      <main>
        <Intro image={data.intro} />
        <Divider />
        <div className='mx-auto flex max-w-screen-xl justify-center p-4'>
          <h2 className='py-8 text-5xl font-bold text-myOrange'>Realizacje</h2>
        </div>

        {/*  */}
        <PortfolioLink
          image={data.cabinets.childImageSharp.gatsbyImageData}
          label='Szafy'
          link='/realizacje/szafy'
          thumbnails={data.cabinetsThumbnails.edges}
        />
        <PortfolioLink
          image={data.kitchens.childImageSharp.gatsbyImageData}
          label='Kuchnie'
          link='/realizacje/kuchnie'
          thumbnails={data.kitchenThumbnails.edges}
        />
        <PortfolioLink
          image={data.furnitures.childImageSharp.gatsbyImageData}
          label='Meble'
          link='/realizacje/meble'
          thumbnails={data.furnitureThumbnails.edges}
        />
      </main>
    </IndexLayout>
  );
};

export const query = graphql`
  {
    intro: file(name: { eq: "IntroWorks" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
      }
    }
    cabinets: file(name: { eq: "cabinets-link" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    kitchens: file(name: { eq: "kitchen-link" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    furnitures: file(name: { eq: "furnitures-link" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    cabinetsThumbnails: allFile(
      filter: { relativeDirectory: { eq: "portfolio/cabinets" } }
      limit: 20
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
    kitchenThumbnails: allFile(
      filter: { relativeDirectory: { eq: "portfolio/kitchen_furniture" } }
      limit: 20
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
    furnitureThumbnails: allFile(
      filter: { relativeDirectory: { eq: "portfolio/various_furniture" } }
      limit: 20
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  }
`;

export default Realizacje;
