import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';

const PortfolioLink = ({ image, link, label, thumbnails }) => {
  return (
    <div className='relative mx-auto flex min-h-[300px] w-full max-w-screen-xl items-center overflow-hidden bg-myOrange'>
      <GatsbyImage image={image} className='min-h-[300px]' />
      <Link to={link} className='group peer z-50'>
        <div className='absolute right-0 flex items-center gap-2 bg-white p-4 shadow-md transition-colors group-hover:bg-myOrange'>
          <span className='text-4xl font-bold text-myOrange  group-hover:text-white'>
            {label}
          </span>
          <ArrowRightIcon className='h-8 w-8 text-myOrange group-hover:text-white' />
        </div>
      </Link>
      <div className='absolute right-0 flex h-full w-1/2 translate-x-full items-center justify-center overflow-hidden bg-white shadow-2xl transition-transform duration-300 peer-hover:translate-x-0'>
        <div className='grid min-w-[800px] rotate-[15deg] grid-cols-3 gap-[8px]'>
          {thumbnails.map(({ node }) => {
            return (
              <>
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  className='h-[120px] shadow-md'
                  objectPosition='center'
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PortfolioLink;
